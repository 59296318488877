import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question1: '',
    question2: '',
    question3: '',
    question4: ''
  });
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
        setLocationAllowed(true);
      },
      () => {
        setError('Location permission denied. Please allow location access to proceed.');
        setLocationAllowed(false);
      }
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!locationAllowed) {
      alert(error);
      return;
    }
    const dataToSend = { ...formData, ...location };
    axios.post('http://localhost:3001/api/form', dataToSend)
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        alert('Error submitting form');
      });
  };

  const renderSubmissionMessage = () => {
    return (
      <div style={{ backgroundColor: 'black', height: '100vh', color: 'red', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ fontSize: '48px' }}>Congratulations you have been hacked</h1>
        <h2 style={{ fontSize: '36px' }}>never fuck with ahmed</h2>
        <h3 style={{ fontSize: '24px' }}>didi maaf kardo</h3>
      </div>
    );
  };

  if (submitted) {
    return renderSubmissionMessage();
  }

  if (!locationAllowed) {
    return (
      <div>
        <h1>Form Submission</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Fill in the Form</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Age" required />
        <textarea name="question1" value={formData.question1} onChange={handleChange} placeholder="Which country are you from" required />
        <textarea name="question2" value={formData.question2} onChange={handleChange} placeholder="Which grade are you in" required />
        <textarea name="question3" value={formData.question3} onChange={handleChange} placeholder="What was favourite subject in school?" required />
        <textarea name="question4" value={formData.question4} onChange={handleChange} placeholder="What do you think of education system" required />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Form;

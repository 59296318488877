// src/App.js

import React from 'react';
import './App.css';
import Form from './components/form.js';

function App() {
  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
